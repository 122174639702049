* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* @font-face kit by Fonts2u (https://fonts2u.com) */
@font-face {
  font-family: "Orbitron Black";
  src: url("assets/orbitron-black.eot?") format("eot"),
    url("assets/orbitron-black.woff") format("woff"),
    url("assets/orbitron-black.ttf") format("truetype"),
    url("assets/orbitron-black.svg#Orbitron-Black") format("svg");
  font-weight: normal;
  font-style: normal;
}

body {
  background-color: #000;
  overflow: hidden;
  font-family: sans-serif;
}

canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.footer-anv {
  font-family: "Orbitron Black";
}
